<template>
  <div class="checkout-layout">
    <CheckoutHeader />
    <Nuxt class="checkout-layout__content" />
    <Notification />
    <CheckoutFooter />
    <WhatsAppChatButton />
  </div>
</template>

<script>
import {
  defineComponent,
  useFetch,
  useMeta,
  useRoute,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import { useUser, useCart, useTranslation } from '~/composables/';
import {
  CheckoutFooter,
  CheckoutHeader,
} from '~/components/Checkout/CommonElements/';
import Notification from '~/components/Notification.vue';
import WhatsAppChatButton from '~/components/General/WhatsAppChatButton.vue';

export default defineComponent({
  name: 'CheckoutLayout',
  components: {
    WhatsAppChatButton,
    CheckoutFooter,
    CheckoutHeader,
    Notification,
  },
  head: {},
  setup() {
    const route = useRoute();
    const {
      isAuthenticated,
      user,
      load: loadUser,
      loading: loadingUser,
      setAuthenticatedState,
    } = useUser();
    const {
      app: { $cookies },
    } = useContext();
    const { cart, load: loadCart } = useCart();

    const { loadAllTranslations } = useTranslation('translations');
    useFetch(async () => {
      setAuthenticatedState();
      await loadAllTranslations();
    });

    const hasAlreadyDeclared = !!$cookies.get('CookieConsent');
    const cookiebot = process && process.browser && window && window.Cookiebot;
    onMounted(async () => {
      if (cookiebot && !hasAlreadyDeclared) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
      if (!user.value && !loadingUser.value) {
        await loadUser();
      }
      if (!cart.value) {
        await loadCart();
      }
    });

    useMeta({
      htmlAttrs: {
        lang: route.value?.path?.split('/')?.[1] === 'it' ? 'it' : 'en',
      },
    });

    return {
      ...useTranslation('translations'),
      isAuthenticated,
      cart,
      user,
    };
  },
});
</script>

<style lang="scss">
@import '../assets/style/variables.scss';
@import '@/static/css/reset.css';
.checkout-layout {
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  &__content {
    flex: 1 0 auto;
  }
  @include from-desktop-min {
    background: linear-gradient(
      to right,
      var(--c-light-grey) 65%,
      var(--c-white) 35%
    );
  }
}
</style>
